import { Component, Input, OnInit } from '@angular/core';
import { ScreeningCheckpoint } from '@enums/screening-checkpoint.enum';
import { CollectedData } from '@models/collected-data.model';
import { CustomerScreeningCheckpoint } from '@models/customer-screening-checkpoint.model';
import { Job } from '@models/job.model';

@Component({
  selector: 'app-collected-data-progress',
  templateUrl: './collected-data-progress.component.html',
  styleUrls: ['./collected-data-progress.component.css']
})
export class CollectedDataProgressComponent implements OnInit {
  @Input() collectedData: CollectedData;
  @Input() job: Job;

  screeningDriversLicenseEnabled = false;
  screeningCertificateEnabled = false;
  screeningInternetEnabled = false;
  isScDriversLicenseDone = false;
  isScEducationDone = false;
  isScEmployerDone = false;
  isScLicenseDone = false;
  isScCitizenshipDone = false;
  isScTradingProhibitionDone = false;
  isScDriversLicenseStarted = false;
  isScLicenseStarted = false;
  isScEducationStarted = false;
  isScEmployerStarted = false;
  isScCitizenshipStarted = false;
  isScTradingProhibitionStarted = false;
  screeningEmployerEnabled = false;
  screeningEducationEnabled = false;
  screeningCitizenshipEnabled = false;
  screeningTradingProhibitionEnabled = false;

  constructor() { }

  ngOnInit() {
    this.setScreeningCheckpointStatuses();
  }

  setScreeningCheckpointStatuses() {
    if (!this.job.isServiceScreening()) {
      return;
    }

    this.screeningDriversLicenseEnabled = this.isScreeningCheckpointEnabled(ScreeningCheckpoint.DRIVERSLICENSE)
    this.screeningCertificateEnabled = this.isScreeningCheckpointEnabled(ScreeningCheckpoint.SANCTIONLIST)
    this.screeningInternetEnabled = this.isScreeningCheckpointEnabled(ScreeningCheckpoint.INTERNET)
    this.screeningEducationEnabled = this.isScreeningCheckpointEnabled(ScreeningCheckpoint.EDUCATION)
    this.screeningEmployerEnabled = this.isScreeningCheckpointEnabled(ScreeningCheckpoint.EMPLOYER)
    this.screeningCitizenshipEnabled = this.isScreeningCheckpointEnabled(ScreeningCheckpoint.CITIZENSHIP)
    this.screeningTradingProhibitionEnabled = this.isScreeningCheckpointEnabled(ScreeningCheckpoint.TRADING_PROHIBITION)
  }

  isScreeningCheckpointEnabled(sc: ScreeningCheckpoint): boolean {
    if (this.job.screeningCheckpoints.length > 0) {
      let jobSc = this.job.screeningCheckpoints.find(scs => scs.checkpoint == sc)

      if (!jobSc) {
        return false;
      }

      if (jobSc.checked) {
        this.isScreeningCheckpointStartedOrDone(sc, jobSc);
        return true;
      }
    }

    return false;
  }

  isScreeningCheckpointStartedOrDone(sc: ScreeningCheckpoint, jobSc: CustomerScreeningCheckpoint) {
    if (jobSc.checkpointStarted) {
      this.setScreeningCheckpointStarted(sc);
    }

    if (jobSc.checkpointCollected) {
      this.setScreeningCheckpointDone(sc);
    }
  }

  setScreeningCheckpointStarted(sc: ScreeningCheckpoint) {
    switch (sc) {
      case ScreeningCheckpoint.DRIVERSLICENSE: this.isScDriversLicenseStarted = true; break;
      case "EDUCATION": this.isScEducationStarted = true; break;
      case "EMPLOYER": this.isScEmployerStarted = true; break;
      case "SANCTIONLIST": this.isScLicenseStarted = true; break;
      case "CITIZENSHIP": this.isScCitizenshipStarted = true; break;
      case "TRADING_PROHIBITION": this.isScTradingProhibitionStarted = true; break;
    }
  }

  setScreeningCheckpointDone(sc: ScreeningCheckpoint) {
    switch (sc.toString()) {
      case "DRIVERSLICENSE": this.isScDriversLicenseDone = true; break;
      case "EDUCATION": this.isScEducationDone = true; break;
      case "EMPLOYER": this.isScEmployerDone = true; break;
      case "SANCTIONLIST": this.isScLicenseDone = true; break;
      case "CITIZENSHIP": this.isScCitizenshipDone = true; break;
      case "TRADING_PROHIBITION": this.isScTradingProhibitionDone = true; break;
    }
  }
}
