import { Pipe, PipeTransform } from '@angular/core';
import { PsPersonStatus } from '@enums/ps-person-status.enum';

@Pipe({
  name: 'swedishTranslationPsPerson'
})
export class SwedishTranslationPsPerson implements PipeTransform {

  transform(value: any): any {
    switch (value) {
      case PsPersonStatus.PENDING_APPROVAL: return 'Inväntar säkerhetsprövning';
      case PsPersonStatus.PENDING_REVOKE: return 'Inväntar avslutad placering';
      case PsPersonStatus.ABORTED: return 'Godkännande timeout';
        case PsPersonStatus.DENIED: return 'Ej godkänd säkerhetsprövning';
        case PsPersonStatus.APPROVED: return 'Godkänd säkerhetsprövning';
        case PsPersonStatus.REVOKED: return 'Avslutad placering begärd';
        default: return value;
    }
  }
}
