import { Deserializable } from '@models/deserializable.model';
import { CollectedDataStatus } from '@enums/collected-data-status.enum';
import { CollectedDataDetails } from '@models/collected-data-details.model';
import { ServiceType } from '@enums/service-type.enum';
import { CollectedDataState } from '@enums/collected-data-state.enum';

export class CollectedData implements Deserializable {
    id: string;
    createTime: string;
    status: CollectedDataStatus;
    dataCollectorUserId: string;
    dataCollectorEmail: string;
    collectedDataDetails: CollectedDataDetails;
    jobId: string;
    deadline: string;
    jobType: ServiceType;
    customerName: String;

    verifyFormerEmployer: boolean;
    verifyEducation: boolean;

    verifyFormerEmployerStarted: boolean;
    verifyEducationStarted: boolean;
    verifyDebtHistoryStarted: boolean;
    verifyextraQuestionStarted: boolean;
    verifySanctionListStarted: boolean;
    verifyTradingProhibitionStarted: boolean;

    employerCollected: boolean;
    educationCollected: boolean;
    debtHistoryCollected: boolean;
    extraQuestionCollected: boolean;
    sanctionListCollected: boolean;
    tradingProhibitionCollected: boolean;

    vehicleOwnershipCollected: boolean;
    driversLicenseCollected: boolean;

    isChecked: boolean;
    extraQuestion: boolean;
    customerId: string;
    editor: string;
    citizenshipState: CollectedDataState;

    constructor(input?: any) {
        this.id = '';
        this.createTime = '';
        this.status = null;
        this.dataCollectorUserId = null;
        this.dataCollectorEmail = '';
        this.collectedDataDetails = new CollectedDataDetails();
        this.jobId = '';
        this.jobType = null;
        this.customerName = '';
        this.deadline = '';
        this.verifyFormerEmployer = false;
        this.verifyEducation = false;
        this.debtHistoryCollected = false;
        this.vehicleOwnershipCollected = false;
        this.driversLicenseCollected = false;
        this.employerCollected = false;
        this.educationCollected = false;
        this.extraQuestionCollected = false;
        this.sanctionListCollected = false;
        this.tradingProhibitionCollected = false;
        this.isChecked = false;
        this.extraQuestion = false;
        this.customerId = '';
        this.editor = null;
        this.citizenshipState = null;

        if (input) { this.deserialize(input); }
    }

    deserialize(input: any): this {
        Object.assign(this, input);
        if (input.collectedDataDetails) {
            this.collectedDataDetails = new CollectedDataDetails(input.collectedDataDetails);
        }
        return this;
    }

    isStatusCreated(): boolean { return this.status == CollectedDataStatus.CREATED }
    isStatusSaved(): boolean { return this.status == CollectedDataStatus.SAVED }
    isStatusDone(): boolean { return this.status == CollectedDataStatus.DONE }

    isDebtHistoryCollected(): boolean { return this.debtHistoryCollected }
    isVehicleOwnershipCollected(): boolean { return this.vehicleOwnershipCollected }
    isEmployerCollected(): boolean { return this.employerCollected }
    isEducationCollected(): boolean { return this.educationCollected }
    isSanctionListCollected(): boolean { return this.sanctionListCollected }
    isTradingProhibitionCollected(): boolean { return this.tradingProhibitionCollected }

    isServiceTypeScreening(): boolean { return this.jobType == ServiceType.SCREENING }
    isServiceTypeSecurityScreening(): boolean { return this.jobType == ServiceType.SECURITY_SCREENING }
    isServiceTypeSecurityScreeningBasic(): boolean { return this.jobType == ServiceType.SECURITY_SCREENING_BASIC }
    isServiceTypeCoWorker(): boolean { return this.jobType == ServiceType.COWORKER }
    isServiceTypeSpecialist(): boolean { return this.jobType == ServiceType.SPECIALIST }
    isServiceTypeExecutive(): boolean { return this.jobType == ServiceType.EXECUTIVE }

    isCitizenshipStateDoNotCheck(): boolean { return this.citizenshipState == CollectedDataState.DO_NOT_CHECK }
    isCitizenshipStateStarted(): boolean { return this.citizenshipState == CollectedDataState.STARTED }
    isCitizenshipStateInProgress(): boolean { return this.citizenshipState == CollectedDataState.IN_PROGRESS }
    isCitizenshipStateDone(): boolean { return this.citizenshipState == CollectedDataState.DONE }

    isStateCheck(): boolean {
        return this.isCitizenshipStateStarted() || this.isCitizenshipStateInProgress() || this.isCitizenshipStateDone();
    }

    isGu(): boolean {
        return this.isServiceTypeSecurityScreening() || this.isServiceTypeSecurityScreeningBasic();
    }

    isBkNotScreening() {
        return this.isServiceTypeCoWorker() || this.isServiceTypeSpecialist() || this.isServiceTypeExecutive();
    }

    checkCitizenship() {
        return this.isStateCheck() && (this.isGu() || this.isBkNotScreening());
    }
}