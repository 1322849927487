import { Pipe, PipeTransform } from "@angular/core";
import { ScreeningCheckpoint } from "@enums/screening-checkpoint.enum";

@Pipe({
  name: 'checkpointIcon'
})
export class CheckpointIconPipe implements PipeTransform {
  transform(checkpoint: ScreeningCheckpoint): string {
    switch (checkpoint) {
      case ScreeningCheckpoint.DRIVERSLICENSE:
        return 'credit_card';
      case ScreeningCheckpoint.EDUCATION:
        return 'school';
      case ScreeningCheckpoint.EMPLOYER:
        return 'work';
      case ScreeningCheckpoint.SANCTIONLIST:
        return 'verified_user';
      case ScreeningCheckpoint.CITIZENSHIP:
        return 'person';
      case ScreeningCheckpoint.TRADING_PROHIBITION:
        return 'money_off';
      case ScreeningCheckpoint.INTERNET:
        return 'language';
      default:
        return 'check_circle';
    }
  }
}