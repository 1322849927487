import { Pipe, PipeTransform } from '@angular/core';
import { CollectedDataStatus } from '@enums/collected-data-status.enum';
import { CsntState } from '@enums/csnt-state.enum';
import { CsntType } from '@enums/csnt-type.enum';
import { CustomerAccess } from '@enums/customer-access.enum';
import { CustomerMotivation } from '@enums/customer-motivation.enum';
import { JobState } from '@enums/job-state.enum';
import { UserGroupId } from '@enums/user-group-id.enum';
import { Gender } from 'src/app/shared/enums/gender.enum';
import { RefOrderState } from 'src/app/shared/enums/ref-order-state.enum';
import { RefQuestionType } from 'src/app/shared/enums/ref-question-type.enum';
import { ServiceType } from '../enums/service-type.enum';
import { CustomerScreeningCheckpoint } from '@models/customer-screening-checkpoint.model';
import { ScreeningCheckpoint } from '@enums/screening-checkpoint.enum';
import { SpiType } from '@enums/spi-type.enum';

@Pipe({
  name: 'swedishTranslation'
})
export class SwedishTranslationPipe implements PipeTransform {

  transform(value: any): any {
    switch (value) {
      // Job
      case JobState.CREATED: return 'Sparad';
      case JobState.PENDING_CONSENT: return 'Inväntar godkännande (granskning)';
      case JobState.DECLINED: return 'Godkännande nekat';
      case JobState.ANALYSING: return 'Granskar';
      case JobState.INTERVIEWING: return 'Intervju inbokad';
      case JobState.PENDING_APPROVAL: return 'Inväntar godkännande (rapport)';
      case JobState.APPROVED: return 'Rapport färdigställd';
      case JobState.SCHEDULED_ORDER: return 'Schemalagd';
      case JobState.ARCHIVED: return 'Arkiverad';
      case JobState.TIMED_OUT: return 'Godkännande timeout';
      case JobState.JOB_SURVEYING: return 'Inväntar svar';
      case JobState.SCHEDULED: return 'Schemalagd';
      case ServiceType.COWORKER: return 'Medarbetare';
      case ServiceType.SPECIALIST: return 'Specialist';
      case ServiceType.EXECUTIVE: return 'Executive';
      case ServiceType.COMPANY: return 'Bolag';
      case ServiceType.BOARDMEMBER: return 'Styrelsekandidat';
      case ServiceType.SCREENING: return 'Screening';
      case ServiceType.PRESCREEN: return 'LFD-kontroll/IDD-kontroll';
      case ServiceType.LFD_FOLLOWUP: return 'Uppföljande LFD-kontroll';
      case ServiceType.SECURITY_SCREENING_BASIC: return 'Grundutredning förenklad';
      case ServiceType.SECURITY_SCREENING: return 'Grundutredning';
      case ServiceType.SECURITY_SCREENING_IMPORT: return 'Grundutredning importerad';
      case ServiceType.SECURITY_CLEARANCE: return 'USÄK';
      case CsntType.JobStart: return 'Granskning';
      case CsntType.ReportApproval: return 'Rapport';
      case CsntType.WithdrawnConsent: return 'Indraget';
      case CsntState.CsntAccepted: return 'Godkänd';
      case CsntState.CsntDeclined: return 'Nekad';
      case CsntState.CsntPendingAnswer: return 'Skickad';
      case CsntState.CsntTimedOut: return 'Samtycke timeout';
      case SpiType.DIGITAL: return 'Digital intervju';
      case SpiType.PHYSICAL: return 'Fysisk intervju';
      case SpiType.EITHER: return 'Fysisk eller digital intervju';
      // User roles
      case UserGroupId.USER_ADMIN: return 'Admin';
      case UserGroupId.COORDINATOR: return 'Koordinator';
      case UserGroupId.ANALYST: return 'Analytiker';
      case UserGroupId.SERVICE_BACKGROUND_CHECK: return 'Bakgrundskontroll';
      case UserGroupId.CUSTOMER_ADMIN: return 'Kundadmin';
      case UserGroupId.SERVICE_REFERENCE_CHECK: return 'Referenstagning';
      case UserGroupId.CUSTOMER_SUPERUSER: return 'Superuser';
      case UserGroupId.DATA_COLLECTOR: return 'Inmatare';
      case UserGroupId.SERVICE_LFD: return 'LFD';
      case UserGroupId.LFD_ORDERER: return 'Beställare';
      case UserGroupId.LFD_RECIPIENT: return 'Mottagare';
      case UserGroupId.LFD_ARCHIVED: return 'Visa arkiverade';
      case UserGroupId.LFD_SCHEDULED: return 'Visa schemalagda';
      case UserGroupId.LFD_DELETE: return 'Ta bort LFD';
      case UserGroupId.LFD_DOWNLOAD_REPORT: return 'Ladda ned rapport';
      case UserGroupId.SPI_COORDINATOR: return 'SPI - Koordinator';
      case UserGroupId.SPI_LEADER: return 'SPI - Ledare';
      case UserGroupId.SPI_DOWNLOAD_REPORT: return 'SPI - Ladda ned bilagor'
      case UserGroupId.SERVICE_SECURITY_SCREENING: return 'SÄK';
      case UserGroupId.SECURITY_SCREENING_DOWNLOAD_REPORT: return 'GU - Ladda ned rapport';
      case UserGroupId.SECURITY_SCREENING_ORDERER_BASIC: return 'GU Förenklad - Beställare';
      case UserGroupId.SECURITY_SCREENING_ORDERER: return 'GU - Beställare';
      case UserGroupId.SECURITY_SCREENING_RECIPIENT: return 'GU - Mottagare';
      case UserGroupId.SCREENING_EXTRA_SERVICES: return 'Screen-Extrafråga';
      case UserGroupId.BACKGROUND_CHECK_ORDERER: return 'Beställare';
      case UserGroupId.BACKGROUND_CHECK_ORDERER_COWORKER: return 'Medarbetare';
      case UserGroupId.BACKGROUND_CHECK_ORDERER_EXECUTIVE: return 'Executive';
      case UserGroupId.BACKGROUND_CHECK_ORDERER_SCREEN: return 'Screen';
      case UserGroupId.BACKGROUND_CHECK_ORDERER_SPECIALIST: return 'Specialist';
      case UserGroupId.BACKGROUND_CHECK_RECIPIENT: return 'Mottagare';
      case UserGroupId.BACKGROUND_CHECK_CHANGE_RECIPIENT: return 'Redigera mottagare'
      case UserGroupId.BACKGROUND_CHECK_DOWNLOAD_REPORT: return 'Ladda ned rapport';
      case UserGroupId.REFERENCE_CHECK_DELETE: return 'Ta bort referenstagning';
      case UserGroupId.REFERENCE_CHECK_ORDERER: return 'Beställare';
      case UserGroupId.REFERENCE_CHECK_RECIPIENT: return 'Mottagare';
      case UserGroupId.REFERENCE_CHECK_DOWNLOAD_REPORT: return 'Ladda ned rapport';
      case UserGroupId.REFERENCE_CHECK_QUESTIONNAIRE_CREATOR: return 'Skapa frågebatteri';
      case UserGroupId.SERVICE_SECURITY_CLEARANCE: return 'USÄK';
      case UserGroupId.SECURITY_CLEARANCE_APPROVE_ACTIVATION: return 'Godkänn aktivering';
      case UserGroupId.SECURITY_CLEARANCE_ARCHIVED: return 'Visa arkiverade';
      case UserGroupId.PROTECTIVE_SECURITY_ASSISTANT_SECURITY_MANAGER: return 'Biträdande säkerhetschef';
      case UserGroupId.SECURITY_CLEARANCE_DOWNLOAD_REPORT: return 'USÄK - Ladda ned rapport';
      case UserGroupId.SECURITY_CLEARANCE_ORDERER: return 'USÄK - Beställare';
      case UserGroupId.SECURITY_CLEARANCE_RECIPIENT: return 'USÄK - Mottagare';
      case UserGroupId.PROTECTIVE_SECURITY_MANAGE_SECURITY_CLEARED: return 'Se säkerhetsprövade';
      case UserGroupId.SECURITY_CLEARANCE_SCHEDULED: return 'Visa schemalagda';
      case UserGroupId.PROTECTIVE_SECURITY_SECURITY_MANAGER: return 'Säkerhetschef';
      case UserGroupId.PROTECTIVE_SECURITY_SECURITY_STAFF: return 'Säkerhetsmedarbetare';
      case UserGroupId.SECURITY_CLEARANCE_RESPONSIBLE_MANAGER: return 'Ansvarig chef';
      case UserGroupId.SERVICE_PROTECTIVE_SECURITY: return 'SÄK';
      case UserGroupId.PROTECTIVE_SECURITY_ORDERER: return 'Beställare';
      case UserGroupId.PROTECTIVE_SECURITY_RECIPIENT: return 'Mottagare';
      case UserGroupId.PROTECTIVE_SECURITY_DOWNLOAD_REPORT: return 'Ladda ned rapport';
      case UserGroupId.PROTECTIVE_SECURITY_CHANGE_RECIPIENT: return 'Redigera mottagare';
      case UserGroupId.PROTECTIVE_SECURITY_DELETE_PS_PERSON: return 'Radera personkort';
      case UserGroupId.PROTECTIVE_SECURITY_IMPORT_ORDER: return 'Importera GU/SPI';
      case UserGroupId.PROTECTIVE_SECURITY_EDIT_SECURITY_CLASS: return 'Redigera säkerhetsklassning';
      case UserGroupId.PROTECTIVE_SECURITY_CREATE_PERSON: return 'Skapa personkort';
      case UserGroupId.PROTECTIVE_SECURITY_EDIT_TAG: return 'Skapa/Redigera taggar';
      case UserGroupId.PROTECTIVE_SECURITY_DELETE_TAG: return 'Radera taggar';
      case UserGroupId.PROTECTIVE_SECURITY_TAG: return 'Taggar';

      // TODO: Legacy? Can these be removed?
      case 'Customer': return 'Kund';
      case 'BackgroundCheck': return 'Bakgrundskontroll';
      case 'Profile': return 'Profil';
      // Customer motivations
      case CustomerMotivation.M1ARBETSMILJO: return 'Arbetsmiljöaspekt';
      case CustomerMotivation.M2PROAKTIVT: return 'Proaktivt säkerhetsarbete';
      case CustomerMotivation.M3SKYDDSVARDEN: return 'Skyddsvärden inom verksamheten';
      case CustomerMotivation.M4POLICY: return 'Intern policy';
      // Customer Screening checkpoints
      case ScreeningCheckpoint.EDUCATION: return 'Verifiering av utbildning/examen';
      case ScreeningCheckpoint.DRIVERSLICENSE: return 'Verifiering av körkortsinnehav';
      case ScreeningCheckpoint.SANCTIONLIST: return 'Verifiering av certifikat/licens';
      case ScreeningCheckpoint.EMPLOYER: return 'Verifiering av arbetsgivare';
      case ScreeningCheckpoint.INTERNET: return 'Internet och sociala medier'; 
      case ScreeningCheckpoint.CITIZENSHIP: return 'Medborgarskap';
      case ScreeningCheckpoint.TRADING_PROHIBITION: return 'Näringsförbud';
      // Customer accesses
      case CustomerAccess.SecurityScreening: return 'Grundutredning';
      case CustomerAccess.BackgroundCheck: return 'Bakgrundskontroll';
      case CustomerAccess.ReferenceCheck: return 'Referenstagning';
      case CustomerAccess.LFD: return 'LFD';
      case CustomerAccess.ProtectiveSecurity: return 'SÄK';

      // Ref Order
      case RefOrderState.CREATED: return 'Sparad';
      case RefOrderState.ORDERED: return 'Kandidaten granskar';
      case RefOrderState.SURVEYING: return 'Referenser granskar';
      case RefOrderState.DONE: return 'Rapport klar';
      case RefOrderState.TIMEOUT: return 'Timeout';
      case RefQuestionType.OPTIONS: return 'Alternativ';
      case RefQuestionType.FREETEXT: return 'Fritext';
      case RefQuestionType.SLIDER: return 'Skala';
      // Misc
      case Gender.MALE: return 'Man';
      case Gender.FEMALE: return 'Kvinna';
      case true: return 'Ja';
      case false: return 'Nej';
      // Collected data status
      case CollectedDataStatus.CREATED: return 'Ej påbörjad';
      case CollectedDataStatus.SAVED: return 'Påbörjad';
      case CollectedDataStatus.DONE: return 'Färdigställd';

      default: return value;
    }
  }
}
