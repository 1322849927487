import { Injectable } from '@angular/core';
import { Job } from '@models/job.model';
import { ServiceType } from '@enums/service-type.enum';
import { HttpBackgroundCheckService } from './http-background-check.service';
import { Order } from '@models/order.model';
import { Customer } from '@models/customer.model';
import { JobState } from '@enums/job-state.enum';
import { ScreeningCheckpoint } from '@enums/screening-checkpoint.enum';

@Injectable()
export class JobHelperService {
  maxDaysSinceCreated = 14;
  blacklistedCustomers = ['2212', '2213', '2219'];

  constructor(private http: HttpBackgroundCheckService) { }

  getOrderDate(job: Job): Date {
    if (job.isOmitConsent() || job.isServiceSecurityClearance()) { return this.getDateFromString(job.createTime); }
    if (!job.consents) { return new Date(0); }
    const consent = job.consents.find(con => con.isTypeJobStart());
    if (!consent) { return null; }
    const orderDate = this.getDateFromString(consent.createTime);//new Date(consent.createTime);
    return orderDate;
  }

  getFirstConsentDate(job: Job): Date {
    const consent = job.consents.find(con => con.isTypeJobStart());
    if (!consent) { return null; }
    const consentDate = this.getDateFromString(consent.time);
    return consentDate;
  }

  getDateFromString(dateString: string): Date {
    // yyyy-MM-dd HH:mm:ss
    if (!dateString || dateString.length != 19) { return null; }

    const dateTime = dateString.split(" ");
    if (dateTime.length != 2) { return null; }

    const date = dateTime[0].split("-");
    if (date.length != 3) { return null; }

    const time = dateTime[1].split(":");
    if (time.length != 3) { return null; }

    const realDate = new Date(+date[0], +date[1] - 1, +date[2], +time[0], +time[1], +time[2]);

    return realDate;
  }

  getDateFromStringWithoutTime(dateString: string): Date {
    // yyyy-MM-dd
    if (!dateString || dateString.length != 10) { return null; }

    const date = dateString.split("-");
    if (date.length != 3) { return null; }

    const realDate = new Date(+date[0], +date[1] - 1, +date[2]);

    return realDate;
  }

  getDeadline(job: Job): Date {
    let workdays = this.addDaysUntilDeadlineByServiceType(job);
    let date;

    if (job.isServiceSecurityClearance()) {
      if (job.isStateSurveying()) {
        date = this.setTimeoutDate(job);
        return this.getScDeadlineDate(date, workdays);
      }

      return null;
    }

    if (!job.consents && job.isStateCreated()) { return null; }
    //If SRI wants to set custom deadline, they set spiDate.
    if (job.spiDate) {
      return this.getDeadlineCustomSpiDate(job.spiDate);
    }
    else if (job.isSPIBooked()) {
      return this.getSpiDeadline(job);
    }

    //If customer has access Omit consent, check createTime instead of consent.time
    else if (job.isOmitConsent() && !job.spiDate) {
      date = this.getDateFromString(job.createTime);
    } else if (!job.spiDate) {
      date = this.getConsentTime(job);
    }

    //If job is saved/consent is not approved date will be null
    if (!date) { return null };

    if (job.isServiceScreening() && job.screeningCheckpoints.length > 0) {
      workdays = this.getAddedDayForScreeningCheckpoints(job, workdays);
    }
    return date = this.getDeadlineDate(date, workdays);
  }

  setTimeoutDate(job: Job): Date {
    let stateSurveyingTime: Date | null = null;

    for (var jobStateLog of job.jobStateLog) {
      if (jobStateLog.state === JobState.JOB_SURVEYING) {
        stateSurveyingTime = jobStateLog.time;
      }
    }

    if (stateSurveyingTime === null) {
      throw new Error("Surveying time not found in jobStateLog.");
    }

    const date = new Date(stateSurveyingTime);
    return date;
  }

  getAddedDayForScreeningCheckpoints(job: Job, workdays: any): number {
    let driversLicenseEnabled = job.screeningCheckpoints.find(sc => sc.checkpoint == ScreeningCheckpoint.DRIVERSLICENSE)
    if (driversLicenseEnabled != undefined && job.screeningCheckpoints.length == 1) {
      workdays = 1;
    } else {
      workdays = 2;
    }
    return workdays;
  }

  getSpiDeadline(job: Job, isInCustomerView?: boolean): Date {
    //Returns Deadline for customer
    if (isInCustomerView) { return this.getCustomerDeadlineWhenSpiDate(job) }
    //Returns Deadline for SRI
    return this.getDeadlineWhenSpiDate(job);
  }

  getConsentTime(job: Job): Date {
    if (!job.consents) { return null; }
    const consent = job.consents.find(con => con.isTypeJobStart() && con.isStateAccepted());
    if (!consent) { return null; }
    const consentDate = this.getDateFromString(consent.time);
    return consentDate;
  }

  addDaysUntilDeadlineByServiceType(job: Job): number {
    let workdays = 0;
    switch (job.service) {
      case ServiceType.COWORKER: workdays = 2; break;
      case ServiceType.SPECIALIST: workdays = 3; break;
      case ServiceType.EXECUTIVE: workdays = 5; break;
      case ServiceType.SCREENING: workdays = 1; break;
      case ServiceType.PRESCREEN: workdays = 23; break;
      case ServiceType.LFD_FOLLOWUP: workdays = 23; break;
      case ServiceType.SECURITY_SCREENING_BASIC: workdays = 2; break;
      case ServiceType.SECURITY_SCREENING: workdays = 3; break;
      case ServiceType.SECURITY_CLEARANCE: workdays = 10; break;
      default: return null;
    }

    return workdays;
  }

  getDeadlineDate(deadlineDate: Date, workdays: number): Date {
    if (deadlineDate.getDay() == 6) {
      deadlineDate.setDate(deadlineDate.getDate() + 1);
      deadlineDate.setHours(17);
      deadlineDate.setMinutes(0);
    } else if (deadlineDate.getDay() == 0) {
      deadlineDate.setDate(deadlineDate.getDate());
      deadlineDate.setHours(17);
      deadlineDate.setMinutes(0);
    }

    let addedDays = 0;
    while (addedDays < workdays) {
      deadlineDate.setDate(deadlineDate.getDate() + 1);
      if (deadlineDate.getDay() !== 0 && deadlineDate.getDay() !== 6) {
        addedDays++;
      }
    }

    return deadlineDate;
  }

  getScDeadlineDate(deadlineDate: Date, workdays: number): Date {
    deadlineDate.setDate(deadlineDate.getDate() + workdays);

    if (deadlineDate.getDay() == 6) {
      deadlineDate.setDate(deadlineDate.getDate() + 2);
      deadlineDate.setHours(17);
      deadlineDate.setMinutes(0);
    } else if (deadlineDate.getDay() == 0) {
      deadlineDate.setDate(deadlineDate.getDate() + 1);
      deadlineDate.setHours(17);
      deadlineDate.setMinutes(0);
    }

    return deadlineDate;
  }

  getOrder(orderId: string): Order {
    let order = new Order();
    order.id = orderId;

    this.http.getOrder(order).subscribe(response => {
      order = response;
    });

    return order;
  }

  //getDay returns a number between 0-6, sunday is 0 monday is 1 etc..
  getCustomerDeadlineWhenSpiDate(job: Job): Date {
    if (job.isNotSPIBooked())
      return null;

    let dateSpi = job.spiDetails.date + " 17:00:00";
    let deadlineDate = null;
    deadlineDate = this.getDateFromString(dateSpi);

    deadlineDate.setDate(deadlineDate.getDate() + 3);
    if (deadlineDate.getDay() == 6 || deadlineDate.getDay() == 0 || deadlineDate.getDay() == 1) {
      deadlineDate.setDate(deadlineDate.getDate() + 2);
    }
    return deadlineDate;
  }

  getDeadlineWhenSpiDate(job: Job): Date {
    if (job.isNotSPIBooked())
      return null;

    let dateSpi = job.spiDetails.date + " 17:00:00";
    let deadlineDate = null;
    deadlineDate = this.getDateFromString(dateSpi);
    deadlineDate.setDate(deadlineDate.getDate() - 2);
    if (deadlineDate.getDay() == 6) {
      deadlineDate.setDate(deadlineDate.getDate() - 2);
      deadlineDate.setHours(17);
      deadlineDate.setMinutes(0);
    }
    else if (deadlineDate.getDay() == 0) {
      deadlineDate.setDate(deadlineDate.getDate() - 2);
      deadlineDate.setHours(17);
      deadlineDate.setMinutes(0);
    }
    return deadlineDate;
  }

  getDeadlineCustomSpiDate(date: string): Date {

    let deadlineDate = this.getDateFromStringWithoutTime(date);

    deadlineDate.setDate(deadlineDate.getDate() - 1);
    if (deadlineDate.getDay() == 6) {
      deadlineDate.setDate(deadlineDate.getDate() - 2);
    } else if (deadlineDate.getDate() == 0) {
      deadlineDate.setDate(deadlineDate.getDate() - 2);
    }

    return deadlineDate;
  }

  isDeadlineClose(deadline: Date): boolean {
    if (!deadline) { return false; }
    if (this.isDeadlinePassed(deadline)) { return false; }

    const dayBeforeDeadline = new Date();
    dayBeforeDeadline.setFullYear(deadline.getFullYear());
    dayBeforeDeadline.setMonth(deadline.getMonth());
    dayBeforeDeadline.setDate(deadline.getDate() - 1);

    const currentDate = new Date();
    return currentDate >= dayBeforeDeadline;
  }

  isDeadlinePassed(deadline: Date): boolean {
    if (!deadline) { return false; }
    const currentDate = new Date();
    return currentDate > deadline;
  }

  isDateWithinLimit(createdDate: string): boolean {
    const currentDate = new Date();
    const maxDate = new Date(this.getDateFromString(createdDate));
    maxDate.setDate(maxDate.getDate() + this.maxDaysSinceCreated);
    return currentDate < maxDate;
  }

  isCustomerHidden(customerId: string): boolean {
    for (const blacklistedId of this.blacklistedCustomers) {
      if (blacklistedId === customerId) { return true; }
    }
    return false;
  }

  filterByAnalyst(analyst: string, jobs: Job[]): Job[] {
    return jobs.filter(job => job.staffId == analyst);
  }

  filterBySpiLeader(spiLeader: string, jobs: Job[]): Job[] {
    return jobs.filter(job => job.spiDetails.spiLeaderId == spiLeader);
  }

  filterByOrdered(jobs: Job[]): Job[] {
    return jobs.filter(job => !job.isStateCreated());
  }

  filterByDeclined(jobs: Job[]): Job[] {
    return jobs.filter(job => job.isStateDeclined() || job.isStateTimedOut());
  }

  filterByCreated(jobs: Job[]): Job[] {

    return jobs.filter(job => {
      return job.isStateCreated()
        && this.isDateWithinLimit(job.createTime)
        && !this.isCustomerHidden(job.customerId);
    });
  }

  filterByUnasigned(jobs: Job[]): Job[] {
    return jobs.filter(job => job.isStateAnalysing() && !job.staffId);
  }

  filterBySpiLeaderUnasigned(jobs: Job[]): Job[] {
    return jobs.filter(job => job.isStateAnalysing() && !job.spiDetails.spiLeaderId || job.isStateInterviewing() && !job.spiDetails.spiLeaderId);
  }

  filterByPending(jobs: Job[]): Job[] {
    return jobs.filter(job => job.isStateAnalysing());
  }

  filterByPendingConsent(jobs: Job[]): Job[] {
    return jobs.filter(job => job.isStatePendingConsent());
  }

  filterByPendingReport(jobs: Job[]): Job[] {
    return jobs.filter(job => job.isStatePendingApproval());
  }

  filterByFinalized(jobs: Job[]): Job[] {
    return jobs.filter(job => job.isStateApproved() || job.isStateDeclined() || job.isStateTimedOut());
  }

  filterByScheduled(jobs: Job[]): Job[] {
    return jobs.filter(job => job.isStateScheduled());
  }

  filterBySurveying(jobs: Job[]): Job[] {
    return jobs.filter(job => job.isStateSurveying());
  }

  filterByArchived(jobs: Job[]): Job[] {
    return jobs.filter(job => job.isStateArchived());
  }

  filterByServiceScreening(jobs: Job[]): Job[] {
    return jobs.filter(job => job.isServiceScreening());
  }

  filterByServiceCoworker(jobs: Job[]): Job[] {
    return jobs.filter(job => job.isServiceCoworker());
  }

  filterByServiceSpecialist(jobs: Job[]): Job[] {
    return jobs.filter(job => job.isServiceSpecialist());
  }

  filterByServiceExecutive(jobs: Job[]): Job[] {
    return jobs.filter(job => job.isServiceExecutive());
  }

  filterByServiceSecurityScreeningBasic(jobs: Job[]): Job[] {
    return jobs.filter(job => job.isServiceSecurityScreeningBasic());
  }

  filterByServiceSecurityScreening(jobs: Job[]): Job[] {
    return jobs.filter(job => job.isServiceSecurityScreening());
  }

  sortByOrderDate(jobs: Job[], ascending: boolean): Job[] {
    return jobs.sort((a, b): number => {
      return this.compare(this.getOrderDate(a), this.getOrderDate(b), ascending);
    });
  }

  sortById(jobs: Job[], ascending: boolean): Job[] {
    return jobs.sort((a, b): number => {
      return this.compare(Number(a.id), Number(b.id), ascending);
    });
  }

  sortByOrderId(jobs: Job[], ascending: boolean): Job[] {
    return jobs.sort((a, b): number => {
      return this.compare(Number(a.orderId), Number(b.orderId), ascending);
    });
  }

  sortByService(jobs: Job[], ascending: boolean): Job[] {
    return jobs.sort((a, b): number => {
      return this.compare(a.service, b.service, ascending);
    });
  }

  sortByCustomerId(jobs: Job[], ascending: boolean): Job[] {
    return jobs.sort((a, b): number => {
      return this.compare(Number(a.customerId), Number(b.customerId), ascending);
    });
  }

  sortByStaffId(jobs: Job[], ascending: boolean): Job[] {
    return jobs.sort((a, b): number => {
      return this.compare(a.staffId, b.staffId, ascending);
    });
  }

  sortByProgress(jobs: Job[], ascending: boolean): Job[] {
    return jobs.sort((a, b): number => {
      return this.compare(a.progress, b.progress, ascending);
    });
  }

  sortByDeadline(jobs: Job[], ascending: boolean): Job[] {
    return jobs.sort((a, b): number => {
      return this.compare(this.getDeadline(a), this.getDeadline(b), ascending);
    });
  }

  sortByState(jobs: Job[], ascending: boolean): Job[] {
    return jobs.sort((a, b): number => {
      return this.compare(a.currState, b.currState, ascending);
    });
  }

  sortBySpiDate(jobs: Job[], ascending: boolean): Job[] {
    return jobs.sort((a, b): number => {
      return this.compare(a.spiDetails.date, b.spiDetails.date, ascending);
    });
  }

  sortByCustomerSpiDate(jobs: Job[], ascending: boolean): Job[] {
    return jobs.sort((a, b): number => {
      return this.compare(a.spiDate, b.spiDate, ascending);
    });
  }

  sortBySpiLeader(jobs: Job[], ascending: boolean): Job[] {
    return jobs.sort((a, b): number => {
      return this.compare(a.spiDetails.spiLeaderId, b.spiDetails.spiLeaderId, ascending);
    });
  }

  sortBySpi(jobs: Job[], ascending: boolean): Job[] {
    return jobs.sort((a, b): number => {
      return this.compare(a.spiDetails, b.spiDetails, ascending);
    });
  }

  sortByActivationTime(jobs: Job[], ascending: boolean): Job[] {
    return jobs.sort((a, b): number => {
      return this.compare(a.scDetails.activationTime, b.scDetails.activationTime, ascending);
    });
  }

  searchJobs(jobs: Job[], search: string, customers: Customer[]): Job[] {
    if (!jobs || !search || !customers) {
      return jobs;
    }

    search = search.toLowerCase();

    return jobs.filter(job => {
      if (job.id != null && job.id.toLowerCase().includes(search))
        return true;

      if (job.orderId != null && job.orderId.toLowerCase().includes(search))
        return true;

      if (search.length > 2 && job.consents) {
        const matchingConsentKey = job.consents.some(c => c.consentKey != null && c.consentKey.toLowerCase().includes(search));
        if (matchingConsentKey)
          return true;
      }

      if (search.length > 3 && job.scDetails.surveyId != null && job.scDetails.surveyId.toLowerCase().includes(search)) {
        return true;
      }

      const matchingCustomerName = customers.some(c => c.id == job.customerId && c.custName != null && c.custName.toLowerCase().includes(search));
      if (matchingCustomerName) {
        return true;
      }

      return false;
    });
  }

  private compare(a: any, b: any, ascending: boolean): number {
    if (a === null || a === undefined) { return -1 * (ascending ? -1 : 1); }
    if (b === null || b === undefined) { return 1 * (ascending ? -1 : 1); }
    if (a > b) { return -1 * (ascending ? -1 : 1); }
    if (a < b) { return 1 * (ascending ? -1 : 1); }
    return 0;
  }

}
