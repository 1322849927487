import { CustomerAccess } from '@enums/customer-access.enum';
import { CustomerMotivation } from '@enums/customer-motivation.enum';
import { CustomerContact } from '@models/customer-contact.model';
import { CustomerScreeningQuestion } from '@models/customer-screening-question.model';
import { Deserializable } from '@models/deserializable.model';
import { CustomerBillingData } from './customer-billing-data.model';
import { CustomerBKConfig } from './customer-bk-config.model';
import { CustomerLFDConfig } from './customer-lfd-config.model';
import { CustomerPSConfig } from './customer-ps-config.model';
import { CustomerRTConfig } from './customer-rt-config.model';
import { ServiceCost } from './service-cost.model';
import { CustomerScreeningCheckpoint } from './customer-screening-checkpoint.model';

export class Customer implements Deserializable {
  id: string;
  custNo: string;
  custName: string;
  custContact: CustomerContact;
  custAdress: string;
  custOrgNo: string;
  custBillingData: CustomerBillingData;
  custMotivations: CustomerMotivation[];
  positionAnalysis: boolean;
  screeningCheckpoints: CustomerScreeningCheckpoint[];
  screeningStandard: boolean;
  access: CustomerAccess[];
  screeningQuestions: CustomerScreeningQuestion[];
  bkConfig: CustomerBKConfig;
  lfdConfig: CustomerLFDConfig;
  psConfig: CustomerPSConfig;
  rtConfig: CustomerRTConfig;
  modified: string;
  fetchStatus: string;
  flag: boolean;
  securityScreenBasic: boolean;
  extendedSecurityScreen: boolean;
  serviceCost: string;
  notes: string;
  invoiceCompanies: string[];

  constructor(input?: any) {
    this.id = null;
    this.custNo = null;
    this.custName = null;
    this.custContact = new CustomerContact();
    this.custAdress = null;
    this.custOrgNo = null;
    this.custBillingData = null;
    this.custMotivations = [];
    this.positionAnalysis = false;
    this.screeningQuestions = [];
    this.bkConfig = new CustomerBKConfig();
    this.lfdConfig = new CustomerLFDConfig();
    this.psConfig = new CustomerPSConfig();
    this.rtConfig = new CustomerRTConfig();
    this.modified = null;
    this.fetchStatus = null;
    this.flag = null;
    this.access = [];
    this.invoiceCompanies = [];
    this.screeningCheckpoints = [];
    this.screeningStandard = false;
    this.serviceCost = null;
    this.notes = null;

    if (input) { this.deserialize(input); }
  }

  static withId(id: string): Customer {
    const customer = new Customer();
    customer.id = id;

    return customer;
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    if (input.custContact) {
      this.custContact = new CustomerContact(input.custContact);
    }
    if (input.custBillingData) {
      this.custBillingData = new CustomerBillingData(input.custBillingData);
    }
    if (input.screeningQuestions) {
      this.screeningQuestions = input.screeningQuestions.map(screeningQuestion => new CustomerScreeningQuestion(screeningQuestion));
    }
    if (input.psConfig) {
      this.psConfig = new CustomerPSConfig(input.psConfig);
    }
    if (input.bkConfig) {
      this.bkConfig = new CustomerBKConfig(input.bkConfig);
    }
    if (input.rtConfig) {
      this.rtConfig = new CustomerRTConfig(input.rtConfig);
    }
    if (input.lfdConfig) {
      this.lfdConfig = new CustomerLFDConfig(input.lfdConfig);
    }
    return this;
  }

  hasSecurityScreening(): boolean { return this.hasCustomerAccess(CustomerAccess.SecurityScreening); }

  hasSecurityClearance(): boolean { return this.hasCustomerAccess(CustomerAccess.SecurityClearance); }

  hasCustomerAccess(groupId: string): boolean {
    if (!this.access) { return false; }
    return this.access.find(customerAccess => customerAccess === groupId) !== undefined;
  }
}
