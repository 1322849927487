export const environment = {
  version: '3.51.1',
  production: true,
  envName: 'PROD',
  debug: true,
  userPoolId: 'eu-north-1_ZJz9AMuDc',
  appClientId: '1rq1r9kh8p2re4ds24fs02mjnk',
  apiUrl: 'https://kq9aiyz2a2.execute-api.eu-north-1.amazonaws.com/prod',
  scsApiUrl: 'https://dwdr5qyczd.execute-api.eu-north-1.amazonaws.com/prod',
  refApiUrl: 'https://1a3tn6s6xc.execute-api.eu-north-1.amazonaws.com/prod',
  tagApiUrl: 'https://75rm4n52b9.execute-api.eu-north-1.amazonaws.com/prod',
  docApiUrl: 'https://no653ypij8.execute-api.eu-north-1.amazonaws.com/prod',
  testCustomers: ['2212', '2213', '2219', '256013']
};
