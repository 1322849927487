import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserGroupId } from '@enums/user-group-id.enum';
import { Customer } from '@models/customer.model';
import { UserGroup } from '@models/user-group.model';
import { User } from '@models/user.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpBackgroundCheckService } from '@services/http-background-check.service';
import { UserPermission, UserPermissionHelperService } from '@services/user-permission-helper.service';
import { PopupService } from '@services/popup.service';
import { PhoneValidator } from '../../validators/phone.validator';

export enum UserAction {
  CREATE, EDIT, VIEW
}

class Group {
  id: UserGroupId;
  checked: boolean;
}

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  @Input() user: User;
  @Input() action: UserAction = UserAction.VIEW;
  @Input() access: UserGroupId = UserGroupId.CUSTOMER_ADMIN;
  @Input() customerAdminCount = -1;
  @Input() customerSuperuserCount = -1;
  @Input() securityClearanceSecurityManagerCount = -1;
  @Input() securityClearanceAssistantSecurityManagerCount = -1;
  userForm: FormGroup;
  loading = false;
  error: string;
  changed = false;
  groups: Group[] = [];
  active: string = "Ja";

  customer: Customer;
  sriStaff = false;

  permissions: UserPermission[];

  MAX_CUSTOMER_ADMINS = 10;
  MAX_CUSTOMER_SUPERUSERS = 10;
  COSTCENTER_MAX_LENGTH = 100;
  MAX_SECURITY_CLEARANCE_SECURITY_MANAGERS = 1;
  MAX_SECURITY_CLEARANCE_ASSISTANT_SECURITY_MANAGERS = 3;

  constructor(
    private modal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private http: HttpBackgroundCheckService,
    private popup: PopupService,
    private permissionHelper: UserPermissionHelperService
  ) { }

  ngOnInit() {
    this.initUserForm();
    this.getPermissions();
  }

  togglePermissionChildren(permission: UserPermission) {
    permission.toggle = !permission.toggle;
  }

  activatePermission(permission: UserPermission) {
    permission.active = !permission.active;
    if (!permission.toggle)
      this.togglePermissionChildren(permission)
    if (!permission.active)
      this.deactivatePermissions(permission.children);
  }

  deactivatePermissions(permissions: UserPermission[]) {
    for (let permission of permissions) {
      permission.active = false;
      this.deactivatePermissions(permission.children);
    }
  }

  getPermissions() {
    if (this.sriStaff) {
      this.permissions = this.permissionHelper.getPermissions();
      this.permissions = this.permissionHelper.toggleActivePermissions(this.permissions, this.user);
    }
    else {
      this.permissions = this.permissionHelper.getPermissions();
      this.permissions = this.permissionHelper.filterSriOnlyPermissions(this.permissions);
      this.permissions = this.permissionHelper.filterFailedRequirements(this.permissions, this.customer);
      this.permissions = this.permissionHelper.toggleActivePermissions(this.permissions, this.user);
    }
  }

  onDone() {
    this.modal.close(this.changed);
  }

  onEdit() {
    this.action = UserAction.EDIT;
  }

  onSave() {
    this.loading = true;
    this.error = null;

    this.getUserFormData();

    this.user.userGroups = [];
    this.insertPermissions(this.permissions);

    this.user.userEmail = this.user.userEmail.toLowerCase();

    // Need to do this when creating accounts with alias. Should be handled in backend.
    if (this.isCreating()) {
      this.user.userId = this.user.userEmail;
    }

    this.http.postUser(this.user).subscribe(response => {
      this.loading = false;
      this.changed = true;
      this.user = response;
      this.onDone();
    }, error => {
      this.loading = false;
      this.error = error.message;
    });
  }

  onDelete() {
    const popupTitle = 'Varning!';
    const popupText = 'Kontot kommer att raderas från systemet. Är du säker på att du vill radera?';
    this.popup.danger(popupTitle, popupText, () => {
      this.loading = true;
      this.error = null;
      this.http.deleteUser(this.user).subscribe(() => {
        this.loading = false;
        this.changed = true;
        this.onDone();
      }, error => {
        this.loading = false;
        this.error = error.message;
      });
    });
  }

  onResetPassword() {
    const popupTitle = 'Återställ lösenord';
    const popupText = 'En återställningskod kommer skickas till det angivna telefonnumret för ' + this.user.getName() + ' som kan användas för att återställa lösenord. Vill du fortsätta?';
    this.popup.confirm(popupTitle, popupText, () => {
      this.loading = true;
      this.error = null;
      this.http.enableUserResetPassword(this.user).subscribe(() => {
        this.loading = false;
        this.changed = true;
        this.onDone();
        this.popup.confirm("SMS skickat!", "Återställningskod har skickats till " + this.user.userPhonenumber, () => { });
      }, error => {
        this.loading = false;
        this.error = error.message;
      });
    });
  }

  initUserForm() {
    this.userForm = this.formBuilder.group({
      email: [this.user.userEmail, [Validators.required, Validators.email]],
      phone: [this.user.userPhonenumber, [Validators.required, PhoneValidator]],
      costCenter: [this.user.costCenter]
    });
  }

  getUserFormData() {
    this.user.userEmail = this.userForm.value.email;
    this.user.userPhonenumber = this.userForm.value.phone;
    this.user.costCenter = this.userForm.value.costCenter;
  }

  insertPermissions(permissions: UserPermission[]) {
    for (let permission of permissions) {
      if (permission.active) {
        const newGroup = new UserGroup();
        newGroup.userGroupId = permission.name;
        this.user.userGroups.push(newGroup);

        this.insertPermissions(permission.children);
      }
    }
  }

  isCreating(): boolean {
    return this.action === UserAction.CREATE;
  }

  isEditing(): boolean {
    return this.action === UserAction.EDIT;
  }

  isViewing(): boolean {
    return this.action === UserAction.VIEW;
  }

  isAccessCustomerAdmin(): boolean {
    return this.access === UserGroupId.CUSTOMER_ADMIN;
  }

  isEditVisible(): boolean {
    return this.isViewing();
  }

  isSaveVisible(): boolean {
    return this.isCreating() || this.isEditing();
  }

  isSaveDisabled(): boolean {
    return this.loading || !this.userForm.valid || !this.isGroupsValid();
  }

  isSendPasswordVisible(): boolean {
    if (this.user.userStatus == "FORCE_CHANGE_PASSWORD") {
      this.active = "Nej, återställ lösenord";
      return true;
    }
  }

  isDeleteVisible(): boolean {
    return this.isViewing();
  }

  isGroupsValid(): boolean {
    let basicRequirements = 0;
    this.permissions.forEach(permission => {
      if (!permission.active) { return; }
      if (this.isGroupBasicRequirement(permission.name)) { basicRequirements++; }
    });

    return basicRequirements > 0;
  }

  isGroupBasicRequirement(groupId: UserGroupId): boolean {
    return groupId === UserGroupId.USER_ADMIN ||
      groupId === UserGroupId.COORDINATOR ||
      groupId === UserGroupId.ANALYST ||
      groupId === UserGroupId.SPI_LEADER ||
      groupId === UserGroupId.SPI_COORDINATOR ||
      groupId === UserGroupId.DATA_COLLECTOR ||
      groupId === UserGroupId.CUSTOMER_ADMIN ||
      groupId === UserGroupId.CUSTOMER_SUPERUSER ||
      groupId === UserGroupId.SERVICE_BACKGROUND_CHECK ||
      groupId === UserGroupId.SERVICE_REFERENCE_CHECK ||
      groupId === UserGroupId.SERVICE_LFD ||
      groupId === UserGroupId.SERVICE_PROTECTIVE_SECURITY
  }

  isGroupDisabled(group: UserGroupId): boolean {
    if (group == UserGroupId.CUSTOMER_ADMIN) {
      if (!this.user.isCustomerAdmin() && this.customerAdminCount >= this.MAX_CUSTOMER_ADMINS) { return true; }
    }
    if (group == UserGroupId.CUSTOMER_SUPERUSER) {
      if (!this.user.isCustomerSuperuser() && this.customerSuperuserCount >= this.MAX_CUSTOMER_SUPERUSERS) { return true; }
    }
    if (group == UserGroupId.PROTECTIVE_SECURITY_SECURITY_MANAGER) {
      if (!this.user.hasSecurityClearanceSecurityManager() && this.securityClearanceSecurityManagerCount >= this.MAX_SECURITY_CLEARANCE_SECURITY_MANAGERS) { return true; }
    }
    if (group == UserGroupId.PROTECTIVE_SECURITY_ASSISTANT_SECURITY_MANAGER) {
      if (!this.user.hasSecurityClearanceAssistantSecurityManager() && this.securityClearanceAssistantSecurityManagerCount >= this.MAX_SECURITY_CLEARANCE_ASSISTANT_SECURITY_MANAGERS) { return true; }
    }
    return false;
  }

  isCustomerAdminCountVisible(group: UserGroupId): boolean {
    return group === UserGroupId.CUSTOMER_ADMIN && this.customerAdminCount !== -1;
  }

  isCustomerSuperuserCountVisible(group: UserGroupId): boolean {
    return group === UserGroupId.CUSTOMER_SUPERUSER && this.customerSuperuserCount !== -1;
  }

  isSecurityClearanceSecurityManagerVisible(group: UserGroupId): boolean {
    return group === UserGroupId.PROTECTIVE_SECURITY_SECURITY_MANAGER && this.securityClearanceSecurityManagerCount !== -1;
  }

  isSecurityClearanceAssistantSecurityManagerVisible(group: UserGroupId): boolean {
    return group === UserGroupId.PROTECTIVE_SECURITY_ASSISTANT_SECURITY_MANAGER && this.securityClearanceAssistantSecurityManagerCount !== -1;
  }
}
