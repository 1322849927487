export enum UserGroupId {
  USER_ADMIN = 'UserAdmin',
  COORDINATOR = 'Coordinator',
  ANALYST = 'Analyst',
  DATA_COLLECTOR = 'DataCollector',
  CUSTOMER_ADMIN = 'CustomerAdmin',
  CUSTOMER_SUPERUSER = 'CustomerSuperuser',
  SPI_LEADER = 'SPILeader',
  SPI_COORDINATOR = 'SPICoordinator',
  SERVICE_BACKGROUND_CHECK = 'ServiceBackgroundCheck',
  BACKGROUND_CHECK_DOWNLOAD_REPORT = 'BKDownloadReport',
  BACKGROUND_CHECK_CHANGE_RECIPIENT = 'BKChangeRecipient',
  BACKGROUND_CHECK_RECIPIENT = 'BKRecipient',
  BACKGROUND_CHECK_ORDERER_SCREEN = 'BKOrdererScreen',
  BACKGROUND_CHECK_ORDERER_COWORKER = 'BKOrdererCoworker',
  BACKGROUND_CHECK_ORDERER_SPECIALIST = 'BKOrdererSpecialist',
  BACKGROUND_CHECK_ORDERER_EXECUTIVE = 'BKOrdererExecutive',
  BACKGROUND_CHECK_ORDERER = 'BKOrderer',
  SERVICE_REFERENCE_CHECK = 'ServiceReferenceCheck',
  REFERENCE_CHECK_DELETE = 'RTDelete',
  REFERENCE_CHECK_QUESTIONNAIRE_CREATOR = 'RTQuestionnaireCreator',
  REFERENCE_CHECK_DOWNLOAD_REPORT = 'RTDownloadReport',
  REFERENCE_CHECK_RECIPIENT = 'RTRecipient',
  REFERENCE_CHECK_ORDERER = 'RTOrderer',
  SERVICE_SECURITY_SCREENING = 'ServiceSecurityScreening',
  SPI_DOWNLOAD_REPORT = 'SPIDownloadReport',
  SECURITY_SCREENING_DOWNLOAD_REPORT = 'SecurityScreeningDownloadReport',
  SECURITY_SCREENING_RECIPIENT = 'SecurityScreeningRecipient',
  SECURITY_SCREENING_ORDERER_BASIC = 'SecurityScreeningOrdererBasic',
  SECURITY_SCREENING_ORDERER = 'SecurityScreeningOrderer',
  SERVICE_LFD = 'ServiceLfd',
  LFD_DELETE = 'LfdDelete',
  LFD_ARCHIVED = 'LfdArchived',
  LFD_SCHEDULED = 'LfdScheduled',
  LFD_DOWNLOAD_REPORT = 'LfdDownloadReport',
  LFD_RECIPIENT = 'LfdRecipient',
  LFD_ORDERER = 'LfdOrderer',
  SCREENING_EXTRA_SERVICES = 'ScreeningExtraServices',
  SECURITY_CLEARANCE_APPROVE_ACTIVATION = 'SCApproveActivation',
  SECURITY_CLEARANCE_ARCHIVED = 'SCArchived',
  SECURITY_CLEARANCE_DOWNLOAD_REPORT = 'SCDownloadReport',
  SECURITY_CLEARANCE_ORDERER = 'SCOrderer',
  SECURITY_CLEARANCE_RECIPIENT = 'SCRecipient',
  SECURITY_CLEARANCE_SCHEDULED = 'SCScheduled',
  SECURITY_CLEARANCE_RESPONSIBLE_MANAGER = 'SCResponsibleManager',
  SERVICE_SECURITY_CLEARANCE = 'ServiceSecurityClearance',
  SERVICE_PROTECTIVE_SECURITY = 'ServiceProtectiveSecurity',
  PROTECTIVE_SECURITY_SECURITY_MANAGER = 'PSSecurityManager',
  PROTECTIVE_SECURITY_ASSISTANT_SECURITY_MANAGER = 'PSAssistantSecurityManager',
  PROTECTIVE_SECURITY_SECURITY_STAFF = 'PSSecurityStaff',
  PROTECTIVE_SECURITY_MANAGE_SECURITY_CLEARED = 'PSManageSecurityCleared',
  PROTECTIVE_SECURITY_ORDERER = 'PSOrderer',
  PROTECTIVE_SECURITY_RECIPIENT = 'PSRecipient',
  PROTECTIVE_SECURITY_DOWNLOAD_REPORT = 'PSDownloadReport',
  PROTECTIVE_SECURITY_CHANGE_RECIPIENT = 'PSChangeRecipient',
  PROTECTIVE_SECURITY_DELETE_PS_PERSON = 'PSDeletePsPerson',
  PROTECTIVE_SECURITY_IMPORT_ORDER = 'PSImportOrder',
  PROTECTIVE_SECURITY_EDIT_SECURITY_CLASS = "PSEditSecurityClass",
  PROTECTIVE_SECURITY_CREATE_PERSON = "PSCreatePerson",
  PROTECTIVE_SECURITY_TAG = "PSTag",
  PROTECTIVE_SECURITY_EDIT_TAG = "PSEditTag",
  PROTECTIVE_SECURITY_DELETE_TAG = "PSDeleteTag"
}
